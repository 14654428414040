var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-command-response-".concat(_vm.functionName),
      "title": _vm.titleModal,
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          staticClass: "rounded-pill px-2",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.response
    }
  }, [_vm.response ? _c('div', {
    staticClass: "position-relative"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "position-absolute p-75 rounded-circle m-25",
    staticStyle: {
      "top": "0",
      "right": "10px",
      "z-index": "9999"
    },
    attrs: {
      "variant": "flat-secondary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": _vm.handleCopyText
    }
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "text-dark",
    attrs: {
      "icon": "CopyIcon",
      "size": "21",
      "title": _vm.$t('copy')
    }
  })], 1), !_vm.isEmpty(_vm.response.booking) ? [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "60vh",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.response.booking.join('\n'),
      "rows": "16",
      "max-rows": "40"
    }
  })] : _vm._e()], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }